import { FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';
import { PaymentMode } from 'src/constant';

export function resetPaymentFormValues(setValue: UseFormSetValue<FieldValues>) {
  setValue('cheque_number', '');
  setValue('draft_number', '');
  setValue('bank_name', '');
  setValue('date', null);
  setValue('merchant_transaction_id', '');
  setValue('atom_transaction_id', '');
  setValue('note', '');
  setValue('transaction_id', '');
  setValue('neft_transaction_id', '');
  setValue('loan_account_id', '');
  setValue('donated_by', '');
  setValue('donated_to', '');
}

export const paymentModeCashFields = ['payment_amount', 'note', 'payment_proof'];
export const paymentModeCashFieldsInstituteAdmin = ['payment_amount', 'date', 'note', 'payment_proof'];

export const paymentModeChequeFields = [
  'payment_amount',
  'cheque_number',
  'bank_name',
  'date',
  'note',
  'payment_proof',
];

export const paymentModeDraftFields = [
  'payment_amount',
  'draft_number',
  'bank_name',
  'date',
  'note',
  'payment_proof',
];

export const paymentModeReconcileFields = [
  'payment_amount',
  'atom_transaction_id',
  'merchant_transaction_id',
  'note',
  'payment_proof',
];

export const paymentModeDigitalFields = ['transaction_id'];

export const paymentModeOfflineNEFTFields = [
  'payment_amount',
  'neft_transaction_id',
  'bank_name',
  'date',
  'note',
  'payment_proof',
];

export const paymentModeLoanFields = [
  'payment_amount',
  'loan_account_id',
  'bank_name',
  'date',
  'note',
  'payment_proof',
];

export const paymentModeDonationFields = [
  'payment_amount',
  'transaction_id',
  'donated_by',
  'donated_to',
  'bank_name',
  'date',
  'note',
  'payment_proof',
];

export const checkFields = (id: string, dropDownValue: string, isInstituteAdmin: boolean) => {
  switch (dropDownValue) {
    case PaymentMode.CASH:
      return isInstituteAdmin
        ? paymentModeCashFieldsInstituteAdmin.includes(id)
        : paymentModeCashFields.includes(id);
    case PaymentMode.CHEQUE:
      return paymentModeChequeFields.includes(id);
    case PaymentMode.DRAFT:
      return paymentModeDraftFields.includes(id);
    case PaymentMode.RECONCILE:
      return paymentModeReconcileFields.includes(id);
    case PaymentMode.ONLINE:
      return paymentModeDigitalFields.includes(id);
    case PaymentMode.OFFLINE_NEFT:
      return paymentModeOfflineNEFTFields.includes(id);
    case PaymentMode.LOAN:
      return paymentModeLoanFields.includes(id);
    case PaymentMode.DONATION:
      return paymentModeDonationFields.includes(id);
    default:
      return false;
  }
};

export const getPaymentModeLabel = (mode: string) => {
  switch (mode) {
    case 'CHEQUE':
      return 'Cheque Number';
    case 'DRAFT':
      return 'Demand Draft Number';
    case 'OFFLINE_NEFT':
      return 'NEFT Transaction ID';
    default:
      return '';
  }
};
