import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';

import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import EditPaymentForm from './EditPaymentForm';
import {
  getStudentFeeBook,
  useCreateStudentPayment,
  useEditPaymentDetails,
  useRefundPayment
} from 'src/graphql/payment';
import { useQuery } from '@apollo/client';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { REFUND_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { ERROR, PAYMENT_MODE_LABEL, PaymentMode, PaymentStatusLabel, SUCCESS } from 'src/constant';
import ERROR_MSG from 'src/constant/error';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { FlatList, ScrollView, TouchableOpacity } from 'react-native';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import LabelValueView from '../../LabelValueView/index.web';
import { clipLongText, compareAndSort, createPaymentDetailObject, sortModuleData } from 'src/utils/utility';
import NoData from '../../NoData';
import { faAddressBook, faHandHoldingUsd } from '@fortawesome/pro-regular-svg-icons';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { useThemeSystem } from 'src/contexts/theme-context';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { paymentFormData, paymentPayload } from 'src/components/organism/Admission/types';
import { EditIcon } from 'src/components/atoms/ActionIcons';
import { resetPaymentFormValues } from './utility';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useUpdateRefundStatus } from 'src/graphql/admission/application';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { getPaymentStatusColor } from 'src/components/organism/Admission/helpers';

interface Props {
  batchId: string;
  studentId: string;
}

const PaymentStatusContentAction = (props: Props) => {
  const { batchId, studentId } = props;
  const { theme } = useThemeSystem();
  const [paymentModal, setPaymentModal] = useState(false);
  const [canClick, setCanClick] = useState(true);
  const [feeBookData, setFeeBookData] = useState<any>({});
  const [partialPayment, setPartialPayment] = useState<number | null>(null);
  const isSubmitting = useRef(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [completeRefundModalState, setCompleteRefundModalState] = useState<boolean>(false);
  const [refundConfirmationModal, setRefundConfirmatioModal] = useState<boolean>(false);
  const refundStatus = ['REFUND_IN_PROCESS', 'REFUND_COMPLETED', 'REFUND_CANCELLED'];

  const { createStudentPayment, createStudentPaymentError } = useCreateStudentPayment();
  const { editPaymentDetails } = useEditPaymentDetails();
  const { refundPayment } = useRefundPayment();
  const { updateRefundStatus } = useUpdateRefundStatus();
  const { setAlertDetails } = useAlertSystem();

  const { t } = useI18n();

  const {
    data: studentFeeBook,
    loading: studentFeeBookLoading,
    error: studentFeeBookError,
  } = useQuery(getStudentFeeBook, {
    variables: {
      batch: batchId,
      user: studentId,
    },
    fetchPolicy: 'network-only'
  });

  const paymentStatus = studentFeeBookLoading ? [] : studentFeeBook?.getStudentFeeBook;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  useEffect(() => {
    if (paymentStatus && Object.keys(paymentStatus)?.length > 0) {
      if (paymentStatus?.feeBook && paymentStatus?.feeBook?.length > 0) {
        sortFeeBookData();
      } else {
        setFeeBookData(paymentStatus);
      }
      if (paymentStatus?.fees?.installmentPayable) {
        setPartialPayment(paymentStatus.fees.installmentPayable);
      }
    }
  }, [paymentStatus]);

  const columns = [
    { label: 'moduleAliasName.label' },
    { label: 'amount.label' },
    { label: 'concession.label' },
    { label: 'payableAmount.label' },
  ];

  const paymentDetailColumns = [
    { label: 'mode.label' },
    { label: 'status.label' },
    { label: 'date.label' },
    { label: 'transactionId.label' },
    { label: 'approveBy.label' },
    { label: 'amount.label' },
    { label: 'action.label' }
  ];

  function sortFeeBookData() {
    let dataRows = [] as any;
    paymentStatus?.feeBook?.forEach((item: any) => {
      dataRows.push({
        ...item,
        orderNo: Math.trunc(item?.orderNo),
        ...(item?.fees && item?.fees?.length > 1
          ? { fees: item?.fees?.slice().sort((a: any, b: any) => compareAndSort(a, b, 'orderNo')) }
          : { fees: item?.fees }),
      });
    });

    const newData = { ...paymentStatus, feeBook: sortModuleData(dataRows) };
    setFeeBookData(newData);
  };

  const closeModal = () => {
    resetPaymentFormValues(setValue);
    setPaymentModal(false);
    if (isEdit) {
      setIsEdit(false);
      setCurrentItem(null);
    }
    clearErrors();
  };

  async function handleCreatePayment(formData: paymentFormData) {
    setCanClick(false);
    isSubmitting.current = true;
    try {
      let paymentPayload: paymentPayload = {
        studentId: studentId,
        paymentDetail: {
          mode: formData?.mode?.value,
        }
      };

      paymentPayload = await createPaymentDetailObject(formData, paymentPayload);
      const createPaymentRes = await createStudentPayment({
        variables: paymentPayload,
        refetchQueries: [{
          query: getStudentFeeBook,
          variables: {
            batch: batchId,
            user: studentId
          }
        }]
      });

      if (createPaymentRes?.data?.createStudentPayment) {
        setAlertDetails({ message: UPDATE_SUCCESS.PAYMENT_STATUS, level: SUCCESS });
        closeModal();
      } else {
        setAlertDetails({
          message: ERROR_MSG.PAYMENT_UNSUCCESSFUL,
          level: ERROR,
        });
      }
    } catch (error: any) {
      setAlertDetails({
        message: ERROR_MSG.PAYMENT_UNSUCCESSFUL + ': ' + error.message,
        level: ERROR,
      });
      setCanClick(true);
    }
    isSubmitting.current = false;
    setCanClick(true);
  }

  const getTotalConcession = (concession: any) => {
    let total = 0;
    concession?.forEach((item: any) => {
      total += item.amount;
    });
    return total;
  };

  const handlePaymentModal = () => {
    setPaymentModal(true);
  }

  const getPaymentValues = (amount: any) => {
    if (amount || amount === 0) {
      return `\u20B9 ${amount}`;
    }
    return '-';
  };

  const GroupItem = ({
    value,
    fontFamily = fonts.semibold,
    valueType = 'amount',
    id = '',
    color = colors.primaryText,
  }: {
    value: string | number;
    fontFamily?: string;
    valueType?: 'amount' | 'label';
    id?: string;
    color?: string;
  }) => {
    return (
      <MediumTextSelect
        value={value ? (valueType == 'label' ? clipLongText(value, 35) : value) : '-'}
        color={color}
        fontFamily={fontFamily}
        id={id}
      />
    );
  };

  const ChildItem = ({ value, valueType = "amount" }: { value: string | number; valueType?: string }) => {
    return (
      <NormalTextSelect
        value={value ? (valueType == "label" ? clipLongText(value, 35) : value) : "-"}
        color={colors.secondaryText} />
    );
  };

  const handleEditPaymentModal = (item: any) => {
    setCurrentItem(item);
    setPaymentModal(true);
    setIsEdit(true);
  };

  async function handleEditAdmissionPayment(formData: paymentFormData) {
    setCanClick(false);
    try {
      let payload: paymentPayload = {
        paymentId: currentItem?.id,
        paymentDetail: {
          mode: formData?.mode?.value,
        },
      };

      payload = await createPaymentDetailObject(formData, payload, true, true);
      const response = await editPaymentDetails({
        variables: payload,
        refetchQueries: [{
          query: getStudentFeeBook,
          variables: {
            batch: batchId,
            user: studentId,
          },
        }]
      });

      if (response?.data?.updatePaymentDetails) {
        setAlertDetails({ message: UPDATE_SUCCESS.PAYMENT, level: SUCCESS });
        closeModal();
        setCanClick(true);
      }
    } catch (error: any) {
      setAlertDetails({
        message: ERROR_MSG.PAYMENT_UNSUCCESSFUL + ': ' + error.message,
        level: ERROR,
      });
    }
    setCanClick(true);
  }

  async function handleRefundPayment() {
    setCanClick(false);
    try {
      const response = await refundPayment({
        variables: { paymentId: currentItem?.id },
        refetchQueries: [{
          query: getStudentFeeBook,
          variables: {
            batch: batchId,
            user: studentId,
          },
        }]
      });
      if (response?.data?.refundPayment) {
        setAlertDetails({ message: REFUND_SUCCESS.REFUND_INITIATED, level: SUCCESS });
        setCanClick(true);
        closeRefundConfirmationModal();
      }
    } catch (error: any) {
      setAlertDetails({
        message: error.message,
        level: ERROR,
      });
    }
    setCanClick(true);
  }

  function handleCompleteRefundModal(item: any) {
    setCompleteRefundModalState(true);
    setCurrentItem(item);
  }

  async function handleCompleteRefund(formValues: paymentFormData) {
    setCanClick(false);
    try {
      let payload: paymentPayload = {
        paymentId: currentItem?.id,
        paymentDetail: {
          mode: formValues?.mode?.value,
        },
      };

      payload = await createPaymentDetailObject(formValues, payload);
      const response = await updateRefundStatus({
        variables: payload,
        refetchQueries: [{
          query: getStudentFeeBook,
          variables: {
            batch: batchId,
            user: studentId,
          },
        }]
      });
      if (response?.data?.updateRefundStatus?.status === 'success') {
        closeCompleteRefundModal();
        setCanClick(true);
        setAlertDetails({ message: REFUND_SUCCESS.REFUND_COMPLETED, level: SUCCESS });
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function closeCompleteRefundModal() {
    resetPaymentFormValues(setValue);
    clearErrors();
    setCompleteRefundModalState(false);
    setCurrentItem(null);
  }

  function handleRefundConfirmationModal(item: any) {
    setCurrentItem(item);
    setRefundConfirmatioModal(true);
  }

  function closeRefundConfirmationModal() {
    setCurrentItem(null);
    setRefundConfirmatioModal(false);
  }

  return (
    <>
      {!studentFeeBookLoading ? (
        <>
          {(feeBookData && feeBookData?.feeBook) ? <>
            <HeaderWrapper>
              <LabelValueView
                label={t("name.label")}
                value={feeBookData && `${feeBookData?.firstName} ${feeBookData?.lastName}`}
                marginBottom={"0px"}
              />
              <LabelValueView
                label={t("rollno.label")}
                value={feeBookData?.rollNo}
                marginBottom={"0px"}
              />
              {feeBookData?.totalPending > 0 && (
                <BtnWrapper>
                  <SecondaryBtn
                    onPress={handlePaymentModal}
                    label={t("makePayment.text")}
                    lines={1}
                  />
                </BtnWrapper>
              )}
            </HeaderWrapper>
            <TableHeadWrapper>
              {
                columns.map(({ label }, index: number) => (
                  <TableColHeadWrapper index={index}>
                    <TableColHead value={t(label)} />
                  </TableColHeadWrapper>
                ))
              }
            </TableHeadWrapper>
            <>
              <TableBodyWrapper>
                <FlatList
                  testID='moduleView'
                  data={feeBookData?.feeBook}
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={styles.contentContainerStyle}
                  keyExtractor={(item: any) => item.id}
                  renderItem={({ item, index }: any) => (
                    <RowView>
                      <FlexRowWrapper>
                        <FeeGrpName>
                          <GroupItem value={item?.groupName} fontFamily={fonts.regular} valueType={"label"} />
                        </FeeGrpName>
                        <FirstCellWithAmount>
                          <GroupItem value={getPaymentValues(item?.amount)} />
                        </FirstCellWithAmount>
                        <CellWithAmount>
                          <GroupItem value={getPaymentValues(item?.totalConcessions)} />
                        </CellWithAmount>
                        <CellWithAmount>
                          <GroupItem id={item?.groupName} value={getPaymentValues(item?.payable)} />
                        </CellWithAmount>
                      </FlexRowWrapper>
                      <>
                        {item?.groupName !== item?.fees[0]?.name && item?.fees?.map((fee: any) => (
                          <ChildModules>
                            <FirstChildName>
                              <ChildItem value={fee?.name} valueType='label' />
                            </FirstChildName>
                            <ChildAmountCell>
                              <ChildItem value={getPaymentValues(fee?.amount)} />
                            </ChildAmountCell>
                            <ComponentsElement>
                              <ChildItem value={getPaymentValues(getTotalConcession(fee?.concessions))} />
                            </ComponentsElement>
                            <ComponentsElement>
                              <ChildItem value={getPaymentValues(fee?.payable)} />
                            </ComponentsElement>
                          </ChildModules>
                        ))}
                      </>
                    </RowView>
                  )}
                />
              </TableBodyWrapper>
              <TableFooterWrapper>
                <TotalWrapper>
                  <ComponentsStartElement>
                    <MediumTextSelect value={t('totalPayment.label')} />
                  </ComponentsStartElement>
                  <DetailsCell>
                    <MediumTextSelect value={getPaymentValues(feeBookData?.totalFee)} />
                  </DetailsCell>
                  <ComponentsElement>
                    <MediumTextSelect value={getPaymentValues(feeBookData?.totalConcession)} />
                  </ComponentsElement>
                  <ComponentsElement>
                    <MediumTextSelect value={getPaymentValues(feeBookData?.totalPayable)} />
                  </ComponentsElement>
                </TotalWrapper>
              </TableFooterWrapper>

              <PaymentDetailsWrapper>
                <PaymentHeading>
                  <HeaderThree
                    value={t('paymentDetails.label')}
                    color={theme?.dialog?.titleColor}
                    lineHeight={32}
                    fontWeight={fontWeight[600]}
                    align='left'
                  />
                </PaymentHeading>
                {feeBookData?.payments && feeBookData?.payments?.length > 0 && (
                  <>
                    <PaymentTableHeadWrapper>
                      {
                        paymentDetailColumns.map(({ label }, index: number) => (
                          <PaymentColHeadWrapper index={index}>
                            <TableColHead value={t(label)} />
                          </PaymentColHeadWrapper>
                        ))
                      }
                    </PaymentTableHeadWrapper>
                    <TableBodyWrapper>
                      <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollView}>
                        <FlatList
                          data={feeBookData?.payments}
                          showsVerticalScrollIndicator={false}
                          contentContainerStyle={styles.contentContainerStyle}
                          keyExtractor={(item: any) => item.id}
                          renderItem={({ item, index }: any) => (
                            <PaymentRowView>
                              <FlexRowWrapper>
                                <PaymentCells>
                                  <GroupItem
                                    value={PAYMENT_MODE_LABEL[item?.mode]}
                                    fontFamily={fonts.regular}
                                    valueType={'label'}
                                  />
                                </PaymentCells>
                                <PaymentCells>
                                  <GroupItem
                                    value={PaymentStatusLabel[item?.status].toUpperCase()}
                                    fontFamily={fonts.regular}
                                    color={getPaymentStatusColor(
                                      PaymentStatusLabel[item?.status],
                                    )}
                                  />
                                </PaymentCells>
                                <PaymentCells>
                                  <GroupItem
                                    value={
                                      item?.date
                                        ? format(new Date(item.date), DT.DATE_FORMAT_SLASH)
                                        : '-'
                                    }
                                    fontFamily={fonts.regular}
                                  />
                                </PaymentCells>
                                <PaymentCells>
                                  <GroupItem
                                    value={
                                      refundStatus?.includes(item?.status)
                                        ? item?.refund?.payId
                                        : item?.detail?.payId
                                    }
                                    fontFamily={fonts.regular}
                                  />
                                </PaymentCells>
                                <PaymentCells>
                                  <GroupItem
                                    value={
                                      refundStatus?.includes(item?.status)
                                        ? item?.refund?.approvedBy
                                        : item?.detail?.approvedBy
                                    }
                                    fontFamily={fonts.regular}
                                  />
                                </PaymentCells>
                                <CellWithAmount>
                                  <GroupItem
                                    value={
                                      refundStatus?.includes(item?.status)
                                        ? item?.refund?.amount
                                          ? `\u20B9 ${item?.refund?.amount}`
                                          : '-'
                                        : item?.detail?.amount
                                          ? `\u20B9 ${item?.detail?.amount}`
                                          : '-'
                                    }
                                  />
                                </CellWithAmount>
                                <CellWithAmount>
                                  {item?.status === 'COMPLETED' || item?.status === 'REFUND_IN_PROCESS' ? (
                                    <>
                                      {item?.status === 'COMPLETED' && (
                                        <ActionIconWrapper>
                                          {item?.mode !== PaymentMode.DIGITAL && (
                                            <EditIcon
                                              tooltipTitle={t('editPayment.label')}
                                              onPress={() => handleEditPaymentModal(item)}
                                            />
                                          )}
                                          <Tooltip title={t('refundPayment.label')}>
                                            <TouchableOpacity onPress={() => handleRefundConfirmationModal(item)}>
                                              <Icon name='refund-payment' size={16} />
                                            </TouchableOpacity>
                                          </Tooltip>
                                        </ActionIconWrapper>
                                      )}
                                      {item?.status === 'REFUND_IN_PROCESS' && (
                                        <ActionIconWrapper>
                                          <Tooltip title={t('completeRefund.label')}>
                                            <TouchableOpacity onPress={() => handleCompleteRefundModal(item)}>
                                              <Icon name='complete-refund' size={16} />
                                            </TouchableOpacity>
                                          </Tooltip>
                                        </ActionIconWrapper>
                                      )}
                                    </>
                                  ) : (
                                    <GroupItem value={'-'} />
                                  )}
                                </CellWithAmount>
                              </FlexRowWrapper>
                            </PaymentRowView>
                          )}
                        />
                      </ScrollView>
                    </TableBodyWrapper>
                  </>
                )}
                <PaymentTotalWrapper paymentDetails={feeBookData?.payments && feeBookData?.payments?.length > 0}>
                  <MediumTextSelect
                    fontFamily={fonts.semibold}
                    value={t('paymentReceived.label')}
                    color={colors.primaryText}
                  />
                  <MediumTextSelect
                    fontFamily={fonts.semibold}
                    value={getPaymentValues(feeBookData?.totalPaid)}
                    color={colors.primaryText}
                  />
                </PaymentTotalWrapper>
                <PaymentReceivedWrapper>
                  <MediumTextSelect
                    fontFamily={fonts.semibold}
                    value={t('paymentDue.label')}
                    color={colors.primaryText}
                  />
                  <MediumTextSelect
                    fontFamily={fonts.semibold}
                    value={getPaymentValues(feeBookData?.totalPending)}
                    color={colors.primaryText}
                  />
                </PaymentReceivedWrapper>
                {Boolean(partialPayment) && (
                  <PaymentReceivedWrapper>
                    <MediumTextSelect
                      fontFamily={fonts.semibold}
                      value={t('partialPayableAmount.label')}
                      color={colors.primaryText}
                    />
                    <MediumTextSelect
                      fontFamily={fonts.semibold}
                      value={`\u20B9 ${partialPayment}`}
                      color={colors.primaryText}
                    />
                  </PaymentReceivedWrapper>
                )}
              </PaymentDetailsWrapper>
            </>
          </> : (
            <CenterAlignView>
              <NoData icon={faAddressBook} text1={t('noPendingPayment.text')} />
            </CenterAlignView>
          )}
        </>
      ) : (
        <CenterAlignView>
          <LoaderSpinner />
        </CenterAlignView>
      )}
      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={paymentModal}
        handleSave={handleSubmit(isEdit ? handleEditAdmissionPayment : handleCreatePayment)}
        Headerpopup={isEdit ? t('editPayment.label') : t('payment.make-payment')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="save.label"
        lineHeight={32}
        height={500}>
        <EditPaymentForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          totalPayableAmount={
            isEdit
              ? currentItem?.detail?.amount
              : partialPayment
              ? partialPayment
              : feeBookData?.payableAmount
          }
          isEdit={isEdit}
          editPaymentDetails={currentItem}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeCompleteRefundModal}
        modalVisible={completeRefundModalState}
        handleSave={handleSubmit(handleCompleteRefund)}
        Headerpopup={t('completeRefund.label')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="save.label"
        lineHeight={32}
        height={512}>
        <EditPaymentForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          totalPayableAmount={Math.ceil(currentItem?.refund?.amount)}
          paymentModes={[
            PaymentMode.CASH,
            PaymentMode.CHEQUE,
            PaymentMode.DRAFT,
            PaymentMode.ONLINE,
            PaymentMode.OFFLINE_NEFT,
          ]}
        />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeRefundConfirmationModal}
        modalVisible={refundConfirmationModal}
        handleSave={handleRefundPayment}
        deleteButtonColor={colors.primaryColor}
        deleteButtonText={'confirm.label'}
        Headerpopup={t('refundPayment.label')}>
          {t('refundPayment.warning.text')}
      </DeleteModal>
    </>
  );
};

const HeaderWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BtnWrapper = styled.View`
  height: 32px;
  min-width: 136px;
`;

const RowView = styled.View`
  margin-bottom: 24px;
`;

const FlexRowWrapper = styled.View`
  flex-direction: row;
`;

const TableFooterWrapper = styled.View`
  position: sticky;
  bottom: 0;
  background-color: ${colors.white};
`;

const TotalWrapper = styled.View`
  flex-direction: row;
  padding: 12px 0px;
  border-top-width: 1px;
  border-top-color: ${colors.dividerColor};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TableHeadWrapper = styled.View`
  flex-direction: row;
  margin-bottom: 16px;
`;

const TableBodyWrapper = styled.View`
  margin-bottom: 16px;
`;

const TableColHeadWrapper = styled.View<{ index: number; }>`
  flex: ${(props: any) => props.index === 0 ? 1.5 : 1};
  justify-content: center;
  align-items: ${(props: any) => (props.index === 0 ? 'flex-start' : 'flex-end')};
  padding-bottom: 4px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const FirstCellWithAmount = styled.View`
  flex: 1;
  align-items: flex-end;
`;

const CellWithAmount = styled.View`
  flex: 1;
  align-items: flex-end;
`;

const DetailsCell = styled.View`
  flex: 1;
  align-items: flex-end;
`;

const ChildModules = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

const FeeGrpName = styled.View`
  flex: 1.5;
  align-items: flex-start;
`;

const ChildAmountCell = styled.View`
  flex: 1;
  align-items: flex-end;
  margin-left: -50px;
`;

const ComponentsElement = styled.View`
  flex: 1;
  align-items: flex-end;
`;

const FirstChildName = styled.View`
  flex: 1.5;
  align-items: flex-start;
  padding-left: 50px;
`;

const ComponentsStartElement = styled.View`
  flex: 1.5;
  align-items: flex-start;
`;

const CenterAlignView = styled.View`
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const PaymentDetailsWrapper = styled.View`
  margin-top: 24px;
`;

const PaymentColHeadWrapper = styled.View<{ index: number; }>`
  flex: 1;
  justify-content: center;
  align-items: ${(props: any) => (props.index === 5 || props.index === 6 ? 'flex-end' : 'flex-start')};
  padding-bottom: 4px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const PaymentCells = styled.View`
  flex: 1;
  align-items: flex-start;
`;

const PaymentRowView = styled.View`
  padding: 16px 0px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const PaymentTableHeadWrapper = styled.View`
  flex-direction: row;
`;

const PaymentHeading = styled.View`
  margin-bottom: 24px;
`;

const PaymentTotalWrapper = styled.View<{ paymentDetails: boolean }>`
  border-top-width: ${(props) => props?.paymentDetails ? 0 : 1}px;
  border-top-color: ${(props) => props?.paymentDetails ? colors.white : colors.borderColor};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  padding-top: ${(props) => props?.paymentDetails ? 0 : 16}px;
  padding-bottom: 16px;
  flex-direction: row;
  justify-content: space-between;
`;

const PaymentReceivedWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  padding-vertical: 16px;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionIconWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 13px;
`;

const styles = {
  contentContainerStyle: {
    flex: 1,
    maxHeight: 400,
  },
  scrollView: {
    maxHeight: 350,
  }
}

export default PaymentStatusContentAction;
