import React from 'react';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import CreateInfoItem from '../../TextComponents/InfoItem';
import { colors, fonts } from 'src/styles/theme/styles';
import OutlineButtton from 'src/components/atoms/Button/OutlineButtton';
import {faFilePdf, faRedo } from '@fortawesome/pro-solid-svg-icons';
import { ReportRequestObject } from 'src/components/organism/Payment/types';
import { ERROR, PAYMENT_REPORT_TYPES, SUCCESS } from 'src/constant';
import { downloadFileRestAPI } from 'src/utils/utility';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { Icon } from 'src/components/atoms/Icon/Icon';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';

interface Props {
  currentReport: ReportRequestObject | null;
  previousReport: ReportRequestObject | null;
  handleRegenerateReport: (id?: string, type?: string) => void;
  batchId?: string;
  admissionId?: string;
}

export default function ExportReportForm(props: Props) {
  const { currentReport, previousReport, handleRegenerateReport, batchId, admissionId } = props;
  const { t } = useI18n();
  let { setAlertDetails } = useAlertSystem();
  const paymentReportDivisionWise = Boolean(
    currentReport?.type === PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE ||
      previousReport?.type === PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE,
  );

  function exportReportPdf() {
    if (batchId) {
      downloadFileRestAPI(`payments/report/${batchId}`, 'payemnts.pdf', err => {
        setAlertDetails({ message: err || 'Failed to download pdf report', level: ERROR });
      });
      setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    }
  }

  function exportReportExcel() {
    if (batchId || admissionId) {
      const downloadUrl = admissionId
        ? `applications/report/${admissionId}`
        : paymentReportDivisionWise
          ? `payments/execlreport/${batchId}/divisionWise`
          : `payments/execlreport/${batchId}`;
      const downloadFileName = admissionId
        ? 'admissions.xlsx'
        : paymentReportDivisionWise
          ? 'batch_payments_divison_wise'
          : 'batch_payments.xlsx';
      downloadFileRestAPI(downloadUrl, downloadFileName, err => {
        console.log('err', err);
        setAlertDetails({ message: err || 'Failed to download excel report', level: ERROR });
      });
      setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    }
  }

  return (
    <ContainerWrapper>
      {currentReport?.status === 'INITIATED' && (
        <FreeshipNoteWrapper>
          <HeaderFive value={t('note.label')} color={colors.noteBlue} />
          <MediumTextSelect
            value={
              previousReport?.status === 'COMPLETED'
                ? t('reportGeneration.message2')
                : t('reportGeneration.message1')
            }
            color={colors.noteBlue}
            fontWeight={'normal'}
            style={{ paddingTop: 4 }}
          />
        </FreeshipNoteWrapper>
      )}
      {(currentReport?.status === 'COMPLETED' || previousReport?.status === 'COMPLETED') && (
        <>
          <ReportContainer>
            <SmallTextAtom value={t('downloadReport.label')} color={colors.primaryText} />
            <ReportButtonContainer>
              {admissionId || paymentReportDivisionWise ? (
                <AdmissionButtonContainer>
                  <IconWrapper>
                    <Icon name="spreadsheet" />
                  </IconWrapper>
                  <TextView>
                    <NormalTextSelect value={'Report.xls'} fontWeight={400} multilineText={true} />
                  </TextView>
                  <Tooltip title="Download">
                    <DownloadTouchableView onPress={exportReportExcel}>
                      <Icon name="download" size={24} />
                    </DownloadTouchableView>
                  </Tooltip>
                </AdmissionButtonContainer>
              ) : (
                <>
                  <ExcelButtonContainer>
                    <OutlineButtton
                      icon={'spreadsheet'}
                      iconColor={colors.green}
                      iconType={'custom'}
                      label={t('excel.label').toUpperCase()}
                      onPress={exportReportExcel}
                      width={103}
                      height={40}
                      iconSize={24}
                      fontSize={1.6}
                      lineHeight={2.4}
                    />
                  </ExcelButtonContainer>
                  <OutlineButtton
                    icon={faFilePdf}
                    iconColor={colors.red}
                    label={t('pdf.label').toUpperCase()}
                    onPress={exportReportPdf}
                    width={85}
                    height={40}
                    iconSize={24}
                    fontSize={1.6}
                    lineHeight={2.4}
                  />
                </>
              )}
            </ReportButtonContainer>
          </ReportContainer>
          <DateWrapper>
            <CreateInfoItem
              label={t('lastGeneratedReportDate.label')}
              value={format(
                currentReport?.status === 'COMPLETED'
                  ? currentReport?.createdAt
                  : previousReport?.createdAt,
                DT.DATE_TIME_SLASH_12_HOUR,
              )}
              fontFamily={fonts.semibold}
            />
            <ButtonWrapper>
              {currentReport?.status === 'COMPLETED' && (
                <OutlineButtton
                  onPress={() =>
                    admissionId
                      ? handleRegenerateReport()
                      : handleRegenerateReport(batchId, currentReport?.type)
                  }
                  label={t('regenerate.label')}
                  icon={faRedo}
                  iconColor={colors.primaryColor}
                  height={'100%'}
                  style={{ alignSelf: 'flex-end' }}
                />
              )}
            </ButtonWrapper>
          </DateWrapper>
        </>
      )}
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.View`
  min-height: 108px;
  padding-bottom: 55px;
`;

const ReportContainer = styled.View``;

const ReportButtonContainer = styled.View`
  margin-top: 4px;
  flex-direction: row;
`;

const ExcelButtonContainer = styled.View`
  margin-right: 16px;
`;

const DateWrapper = styled.View`
  max-height: 40px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  aline-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.View`
  display: flex;
  justify-content: center;
`;

const AdmissionButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  aline-items: center;
  width: 100%;
`;

const DownloadTouchableView = styled.TouchableOpacity`
  align-self: center;
`;

const TextView = styled.View`
  align-self: center;
  max-width: 80%;
`;

const FreeshipNoteWrapper = styled.View`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.noteBlue};
  padding: 16px 32px;
  border-radius: 4px;
  background-color: ${colors.noteBg};
  margin-bottom: 24px;
`;

const IconWrapper = styled.View`
  align-self: center;
`;